<template>
  <div>
    <v-row class="pa-0">
      <!-- Company ID -->
      <v-col cols="12" sm="12" md="12" lg="10" class="pa-2 pb-0">
        <v-autocomplete ref="autocomplete" :rules="[inputValidation]" :error="hasError" :error-messages="errorMessage" color="primarytext"
          v-model="selectedCompany" :items="companytList" item-text="custno" label="Company Id" outlined item-value="comp_id" class="pa-0" dense
          :disabled="!edit && name !== 'newBusiness'" @change="onselectCompanyId(selectedCompany)" persistent-placeholder>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="2" class="pa-3 px-5 mb-0">
        <v-btn v-if="(actionSetter('AddCompanyBusiness'))" :disabled="!edit" class="pa-0 my-0 float-right" @click="addCompanyCalled = true" dense
          small outlined title="Add Company" color="primarytext">
          <v-icon small> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <companyComp v-if="addCompanyCalled" name="newCompany" :compCalled="addCompanyCalled" @hide="closeCompany()" :companyId="selectedCompanyId"
      :userId="userId" :actionList="actionList" @refreshCompany="refreshCompany" location="business" :detailName="detailName" />
    <!-- Address -->

    <v-row class="pt-0">
      <v-col cols="12" sm="12" md="12" lg="12" class="px-5 py-0">
        <!-- Customer address display -->
        <div class="pl-3 pt-0">
          <template>
            <b>{{ contactName }}
            </b><br />
            <b>{{ companyName }}</b><br />
            {{ address1 }}<br />
            {{ city }}
            <v-template v-if="city != '' && selectedState != ''">
              ,
            </v-template>
            {{ selectedState }}
            <v-template v-if="selectedState != '' && zip != ''">
              ,
            </v-template>
            {{ zip }}<br />
            {{ selectedCountry }}<br />
            {{ email }} &nbsp;
            {{ phone1 }}&nbsp; {{ phone2
            }}
            <br />
          </template>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Utility from '../../shared/utility';
import EncryptUtility from '../utility/encrypt-utility';
import companyComp from "../common/common-company-comp-existing.vue";
export default {
  name: 'companyAddressComp',
  props: {
    detailName: String,
    projectKey: String,
    businessKey: String,
    comp_id: String,
    edit: Boolean,
    shippLableCheck: Boolean,
    name: String,
    actionList: Array
  },
  data: () => ({
    name: '',
    userId: EncryptUtility.localStorageDecrypt('userID'),
    cell: '',
    email: '',
    selectedCompany: '',
    edit: true,
    showAdd: false,
    companyName: '',
    showSOCheckBox: false,
    address1: '',
    phone2: '',
    title: '',
    phone1: '',
    adrdess2: '',
    postal: '',
    contactName: '',
    fax: '',
    city: '',
    companytList: [],
    selectedCountry: '',
    selectedState: '',
    refreshCompList: false,
    addCompanyCalled: false,
    shiploc_key_flag: false,
    bill2_key_flag: false,
    contact_key_flag: false,

  }),
  created() { },
  async mounted() {
    console.log(this.comp_id)
    this.getCompanyList();
    if (this.comp_id?.length > 0) {
      this.selectedCompany = this.comp_id;
    }
    this.shippLableCheck ? this.showAdd = true : this.showAdd = false
  },
  methods: {
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    inputValidation(value) {
      if (!value) {
        this.hasError = true;
        return 'Field is required';
      }
      this.hasError = false;
      return true;
    },
    validate() {
      this.onselectCompanyId(this.selectedCompany);
      return !this.hasError;
    },
    closeCompany() {
      this.addCompanyCalled = false;
    },
    refreshCompany(checkCompany, refresh, custno) {
      this.refreshCompList = refresh;
      if (this.detailName == "Contact") {
        this.contact_key_flag = checkCompany
      }
      else if (this.detailName == "Ship-To") {
        this.bill2_key_flag = checkCompany
      }
      else if (this.detailName == "Shipped-From") {
        this.shiploc_key_flag = checkCompany
      }
      if (this.refreshCompList) {
        this.getCompanyList();
        this.selectedCompany = custno;
        this.comp_id = custno
      }
    },
    //Get Company List
    getCompanyList() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      if (this.detailName == 'Contact') {
        this.axios
          .get(`/cp/company_details?pass=1&user_id=${this.userId}&proj_key=0&bu_key=0`)
          .then((response) => {
            if (response.status == 200) {
              let responseData = JSON.parse(response.data.body.message).CompanyInfo;
              this.companytList = responseData;

              if (this.comp_id && this.detailName == 'Contact') {
                this.onselectCompanyId(this.comp_id);
              }
              else {
                this.onselectCompanyId(this.selectedCompany);
              }
              LoaderDialog.visible = false;
              this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
              var Alert = {
                type: 'error',
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit('ConfigModule/Alert', Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          })

          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            var Alert = {
              type: 'error',
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit('ConfigModule/Alert', Alert);
            this.errorMessage = error.message;
          });
      }
      //for pass =2
      else if (this.detailName == 'Ship-To') {
        this.axios
          .get(`/cp/company_details?pass=7&user_id=${this.userId}&proj_key=0&bu_key=0`)
          .then((response) => {
            if (response.status == 200) {
              let responseData = JSON.parse(response.data.body.message).CompanyInfo;
              this.companytList = responseData;
              if (this.comp_id && this.detailName == 'Ship-To') {
                this.onselectCompanyId(this.comp_id);
              }
              else this.onselectCompanyId(this.selectedCompany);
              LoaderDialog.visible = false;
              this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
              var Alert = {
                type: 'error',
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit('ConfigModule/Alert', Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            var Alert = {
              type: 'error',
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit('ConfigModule/Alert', Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //On company selecting
    onselectCompanyId(selectedCompany) {
      this.hasError = !selectedCompany;
      this.errorMessage = this.hasError ? 'Field is Required' : '';
      let companySelected = this.companytList.find(
        (element) => element.comp_id == selectedCompany,
      );

      if (companySelected != undefined && companySelected != null) {
        this.selectedCompany = companySelected.comp_id
        this.companyName = companySelected.company;
        this.address1 = companySelected.address1;
        this.address2 = companySelected.address2;
        this.city = companySelected.city;
        this.selectedState = companySelected.state;
        this.postal = companySelected.Zip;
        this.selectedCountry = companySelected.country;
        this.contactName = companySelected.ContName;
        this.title = companySelected.title;
        this.email = companySelected.email;
        this.phone1 = companySelected.phone1;
        this.phone2 = companySelected.phone2;
        this.cell = companySelected.cell;
        this.fax = companySelected.fax;
        this.ownerKey = companySelected.comp_id;
        this.$emit('id', this.ownerKey, this.detailName, this.shiploc_key_flag, this.contact_key_flag, this.bill2_key_flag, this.showSOCheckBox
        );

      }
    },
  },
  components: {
    companyComp,
  },
};
</script>
