import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/encrypt-utility";
import companyAddressComp from "../app-business-company-comp.vue";
import CommonDialog from "@/olp-components/common/common-dialog.vue";
import adminService from "./admin-service";
export default {
  name: "businessComp",
  required: [(v) => !!v || "Field is required"],
  props: {
    name: String,
    compCalled: Boolean,
    projectData: Array,
    bu_id: String,
    actionList: Array,
    edit: Boolean,
  },
  data: () => ({
    activeTab: -1,
    showDialog: false,
    showUpdate: false,
    required: (v) => !!v || "Field is required",
    min2Rule: (v) =>
      (v || "").length > 1 || "Min Length must be 2 characters or More",
    max50Rule: (v) =>
      (v || "").length <= 50 || "Max Length must be 50 characters or less",
    alphaNumeric: (v) =>
      !v || /^[a-zA-Z0-9_]*$/i.test(v) || "Only Alphanumberic allowed",
    max100Rule: [
      (v) => !!v || "Field is required",
      (v) =>
        (v || "").length <= 100 || "Max Length must be 100 characters or less",
    ],
    max10Rule: (v) =>
      (v || "").length <= 10 || "Max Length must be 10 characters or less",
    max20Rule: [
      (v) => !!v || "Field is required",
      (v) =>
        (v || "").length <= 20 || "Max Length must be 20 characters or less",
    ],
    max5Rule: [
      (v) => !!v || "Field is required",
      (v) => (v || "").length <= 5 || "Max Length must be 5 characters or less",
    ],
    numberRule: [(v) => parseInt(v) >= 0 || "Field is required"],
    max10Alphanumeric: [
      (v) => !v || /^[a-zA-Z0-9_]*$/i.test(v) || "Only Alphanumberic allowed",
      (v) =>
        (v || "").length <= 10 || "Max Length must be 10 characters or less",
    ],
    max100Alphanumeric: [
      (v) => !v || /^[a-zA-Z0-9_]*$/i.test(v) || "Only Alphanumberic allowed",
      (v) =>
        (v || "").length <= 100 || "Max Length must be 100 characters or less",
    ],
    max100RuleReq: [
      // (v) => parseInt(v) == 0 || "Field must be 0",
      (v) => parseInt(v) < 100 || "Field should be less than 100 ",
      (v) => parseInt(v) >= 0 || "Field should be equal or greather than 0",
    ],
    businessName: "",
    businessCode: "",
    message: "Are you sure you want to delete this item?",
    outLineText: "",
    disableAdd: true,
    edit: false,
    businessId: 0,
    contact_key: "",
    contact_KeyPass: "",
    retrun_to_flag: false,
    isActive: true,
    Shipped_KeyPass: "",
    ship_Key: "",
    disableFailure: false,
    update: false,
    returnProxy: "",
    shippLableCheck: false,
    tab: [1, 2],
    userId: EncryptUtility.localStorageDecrypt("userID"),
    selectedProject: "",
    allowAddBusiness: false,
    tabs: ["Contact", "Ship-To"],
    selectedPayment: 0,
    allowedExepected: false,
    bill2List: [],
    contactList: [],
    postal: "",
    validate: true,
    isFormValid: false,
    valid: false,
    shiploc_key_flag: false,
    bill2_key_flag: false,
    contact_key_flag: false,
    ship_to_key: "",
  }),
  created() {
    // for Update
    if (this.name != "newBusiness") {
      this.businessId = this.bu_id;
      this.getBusinessDetails();
    } else {
      this.activeTab = 0;
      this.edit = true;
    }
  },
  methods: {
    getId(value, name, contact_key_flag, bill2_key_flag) {
      if (name == "Contact") {
        this.valid = true;
        this.contact_key = value;
        this.contact_key_flag = contact_key_flag;
      } else if (name == "Ship-To") {
        this.ship_to_key = value;
        this.valid = true;
        this.bill2_key_flag = bill2_key_flag;
      }
    },

    disableTextbox() {
      if (
        this.outLineText == "success" &&
        this.businessName.length > 1 &&
        this.businessCode.length > 1
      ) {
        this.disableAdd = false;
      } else {
        this.disableAdd = true;
      }
    },
    //Add Busissines
    async onClickAdd() {
      if (this.$refs.businessAddForm.validate()) {
        let workCenters = [];
        if (!this.contact_key) {
          document.getElementById("tab1").click();
          let Alert = {
            type: "error",
            isShow: true,
            message: "Contact Address is empty",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
        if (!this.ship_to_key) {
          document.getElementById("tab2").click();
          let Alert = {
            type: "error",
            isShow: true,
            message: "Ship to address is empty",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
        if (this.ship_to_key && this.contact_key) {
          let addObject = {
            BuJson: JSON.stringify({
              bu_id: this.businessId,
              proj_key: this.selectedProject,
              contact_key: this.contact_key,
              bu_name: this.businessName,
              bu_code: this.businessCode,
              active: this.isActive ? "1" : "0",
              ntf_code: this.ntfCode,
              ntf_desc: this.ntfDescription,
              ntf_key: 1,
              rcv_scrap_key:
                this.selectedFailCode != undefined &&
                this.selectedFailCode != null
                  ? this.selectedFailCode
                  : "",
              repair_loop_num:
                this.internalRepair != undefined && this.internalRepair != null
                  ? this.internalRepair
                  : 0,
              repair_loop_failc_key:
                this.selectedInternalFailCode != undefined &&
                this.selectedInternalFailCode != null
                  ? this.selectedInternalFailCode
                  : "",
              bill2_key: this.bill2_key,
              pterms: this.selectedPayment,
              fob: this.fob,
              ei_on: this.seeBeyond,
              proxy_name: this.outboundProxy,
              rtn_proxy_name: this.returnProxy,
              expected_sn: this.allowedExepected ? "1" : "0",
              pick_audit_rate: this.auditRate,
              UserId: parseInt(this.userId),
              workcenters: workCenters,
              shiploc_key: this.ship_Key,
              default_so_prefix: this.soPrefix,
              default_po_prefix: this.poPrefix,
              shiploc_key_flag: this.shiploc_key_flag,
              bill2_key_flag: this.bill2_key_flag,
              contact_key_flag: this.contact_key_flag,
              retrun_to_flag: this.retrun_to_flag,
              api_key: this.buApiKey,
              ship_to_key: this.ship_to_key,
              manual_allocation: this.manual_allocation,
            }),
          };
          let res = await adminService.postBusinessUpsert(
            "post",
            addObject,
            true
          );
          console.log(res);
          this.compCalled = false;
          this.$emit("businessChange");
          this.name = "";
          this.deleteBusinessDialog = false;
        }
      }
    },
    //To pre-populate the values
    assignValues() {
      this.businessName = this.companyInfo[0].business;
      this.businessCode = this.companyInfo[0].bu_code;
      this.isActive = this.companyInfo[0].active;
      this.selectedProject = this.companyInfo[0].proj_key;
      this.outboundProxy = this.companyInfo[0].proxy_name;
      this.returnProxy = this.companyInfo[0].rtn_proxy_name;
      this.ntfCode = this.companyInfo[0].ntf_code;
      this.ntfDescription = this.companyInfo[0].ntf_desc;
      this.allowedExepected = this.companyInfo[0].expected_sn;
      this.auditRate = this.companyInfo[0].pick_audit_rate;
      this.fob = this.companyInfo[0].fob;
      this.bill2_KeyPass = this.companyInfo[0].b_custno;
      this.contact_KeyPass = this.companyInfo[0].c_custno;
      this.contact_key = this.companyInfo[0].c_comp_id;
      this.bill2_key = this.companyInfo[0].b_comp_id;
      this.ship_Key = this.companyInfo[0].shiploc_key;
      this.Shipped_KeyPass = this.companyInfo[0].shiploc_key;
      this.shippLableCheck = this.companyInfo[0].isShipLocDiff;
      this.soPrefix = this.companyInfo[0].default_so_prefix;
      this.poPrefix = this.companyInfo[0].default_po_prefix;
      this.internalRepair = this.companyInfo[0].repair_loop_num;
      this.buApiKey = this.companyInfo[0].api_key;
      this.manual_allocation = this.companyInfo[0].manual_allocation;
      this.ship_to_key = this.companyInfo[0].ship_to_key;
    },
    //On click Cancel
    onClickCancel() {
      if (this.deleteBusinessDialog) {
        this.showDialog = false;
      } else {
        this.compCalled = false;
        this.$emit("hide");
        this.name = "";
      }
    },
    onClickDialog() {
      this.showDialog = true;
    },
    //Delete Business
    deleteBusiness() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let deleteBusiness = {
        bu_id: parseInt(this.bu_id),
        UserId: parseInt(this.userId),
      };
      this.axios
        .post("/cp/business_delete", deleteBusiness)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.deleteBusinessDialog = false;
            this.onClickCancel();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //To Get Business details
    getBusinessDetails() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(
          `/olp/re_business_details?bu_id=${this.businessId}&user_id=${this.userId}`
        )
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let details = JSON.parse(response.data.body.message);
            this.companyInfo = details.CompInfo;
            this.assignValues(this.companyInfo);
            this.activeTab = 0;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    // change of tab
    changeTab(index) {
      this.activeTab = index;
    },
    //TO allow edit on Click of an icon
    allowEdit() {
      this.edit = true;
      this.showUpdate = true;
    },
    //Close the popup
    closeDialog() {
      this.compCalled = false;
      this.$emit("hide");
      this.name = "";
      this.deleteBusinessDialog = false;
    },
    //To check if the user has access access to that action or not
    actionSetter(action) {
      return Utility.actionSetter(this.actionList, action);
    },
    checkBuiness(pass, value) {
      if (pass == 2) {
        this.businessCode = this.businessCode?.toUpperCase();
        this.buApiKey = this.businessCode;
      } else {
        this.businessName = this.businessName?.toUpperCase();
      }
      if (value?.length > 1) {
        {
          let buObj = {
            user_id: this.userId,
            pass: pass == 1 ? 1 : 2,
            bu_code: pass == 1 ? "" : value,
            business: pass == 1 ? value : this.businessName,
          };
          this.axios
            .post("/cp/business_check_unique", buObj)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                this.outLineText = "success";
              } else {
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.outLineText = "error";
              }
            })
            .catch((error) => {
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.outLineText = "error";
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        }
      }
    },
  },
  components: {
    companyAddressComp,
    CommonDialog,
  },
};
